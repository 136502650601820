/* Modal Overlay */
.form-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.form-modal-container {
  position: relative;
  width: 50%;

  height: auto;
  max-height: 85%;
  background: white;
  border-radius: 20px;
  padding-bottom: 5rem;
  overflow-x: hidden;
  // overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
  // padding-bottom: 5rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    // max-height: unset;
    max-width: unset !important;
  }
  .loan-request-form {
    padding-inline-end: 1rem;
    h1 {
      &.carousel-header {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #01828d;
      }
      span {
        font-weight: 700;
        color: #e9581e !important;
      }
    }
    .navigation-sign {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;
      align-items: center;
      padding-inline: 10%;
      .from {
        min-width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .to {
        min-width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    //max-height: unset;
  }
}

/* Header */
.form-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  direction: ltr;

  &[dir="rtl"] {
    justify-content: flex-start;
  }
}

.form-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.form-modal-close {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.form-modal-close:hover {
  color: #333;
}

/* Content */
.form-modal-body {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0px 20px 20px 20px;
  border: none !important;
  flex: 1;
  scrollbar-width: none;
  direction: ltr;

  // Custom scrollbar styling
  &::-webkit-scrollbar {
    width: 8px; // Scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e9581e; // Scrollbar thumb color
    border-radius: 4px; // Rounded edges
  }

  /*  &::-webkit-scrollbar-thumb:hover {
      background-color: $navActiveColor; 
    } */

  &::-webkit-scrollbar-track {
    background: #f0f3f4; // Scrollbar track color
  }
}

/* Footer */
.form-modal-footer {
  display: flex;
  justify-content: center;
  // padding: 16px;
  width: 100%;
  position: absolute;
  bottom: 2%;
  padding-inline-end: 3rem;
  // left: 12%;
  // right: 15%;
}

.form-modal-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2fa3b5;
  color: white;
  border: none;
  // padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  height: 55px;
  font-weight: 600;
  box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25),
    inset -2px -4px 4px rgba(0, 0, 0, 0.25);
}

.form-modal-close-button:hover {
  background: white;
  color: #02a9bf;
}
.form-modal-close-button:disabled {
  background: #64656980;
  color: white;
  cursor: not-allowed;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
