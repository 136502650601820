.step-one {
  flex: 1;
  display: flex;
  flex-direction: column;
  direction: rtl;

  h1 {
    &.carousel-header {
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      color: #01828d;
    }
    span {
      font-weight: 700;
      color: #e9581e !important;
    }
  }
  .navigation-sign {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    direction: rtl;
    padding-inline: 12.5%;
    @media screen and (max-width: 1275px) {
      padding-inline: 10%;
      // padding-inline-end: 10rem;
    }
    @media screen and (max-width: 620px) {
      padding-inline: 0;
      padding-inline-end: 10rem;
    }
    .from {
      min-width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
    .to {
      min-width: max-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .subContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-color: #ebebeb;
    border-width: 1px;
    border-style: solid;
    border-radius: 0.6rem;
    min-height: max-content;
    gap: 2rem;
  }
  .id-img {
    display: flex;
    width: 18.625rem !important;
    height: auto;
    padding-block: 1rem;
    padding-inline-start: 1rem;
  }
  .right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 100%;
    height: 100%;
    padding-inline-start: 1rem;
    row-gap: 1rem;
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 22.5px;
      text-align: right;
    }
  }
}
.terms-container {
  display: flex;
  // flex-direction: column;
  margin-block: 1rem;
  align-items: center;
  gap: 0.5rem;

  span {
    font-size: 18px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: right;
  }
  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}

.terms-and-conditions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 3rem;
  height: 50%;
  align-items: center;
  max-width: 560px;
  padding-right: 20px;
  overflow-y: scroll;
  direction: ltr;
  height: 100%;
  /*   &::-webkit-scrollbar {
    position: absolute;
    left: 0;

    &[dir="rtl"] {
      left: auto;
      right: 0;
    }
  } */

  // Custom scrollbar styling
  &::-webkit-scrollbar {
    width: 8px; // Scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e9581e; // Scrollbar thumb color
    border-radius: 4px; // Rounded edges
  }

  /*  &::-webkit-scrollbar-thumb:hover {
    background-color: $navActiveColor; 
  } */

  &::-webkit-scrollbar-track {
    background: #f0f3f4; // Scrollbar track color
  }

  h1 {
    color: #01828d;
    font-weight: 700;
    font-size: 25px;
  }
  .terms-container {
    padding: 15px;
    direction: rtl; /* Ensures proper right-to-left layout */
    font-family: Arial, sans-serif;
  }

  .terms-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #0088a6; /* Match the header color */
    margin-bottom: 10px;
  }

  .terms-text {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.8;
    color: #333333;
    margin-bottom: 15px;
  }

  .terms-subheader {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }

  .terms-list {
    list-style-type: disc; /* Adds the dots */
    margin: 0;
    padding-right: 20px;
  }

  .terms-list li {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.8;
    color: #333333;
    margin-bottom: 10px;
    text-align: right;
  }
  ul {
    //overflow-y: scroll;
    // scrollbar-color: #e9581e !important;
  }
}

.sorry-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  div {
    display: flex;
    justify-content: center;
  }
  svg {
    width: 177px !important;
    height: 177px !important;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    color: #35363d !important;
  }
  p {
    text-align: center;
    color: #6b7280;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2.5rem;
    max-width: 330px;
  }
}
.success-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  svg {
    width: 177px !important;
    height: 177px !important;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    color: #35363d !important;
  }
  p {
    text-align: center;
    color: #6b7280;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2.5rem;
    max-width: 410px;
  }
}
