/* SCSS Variables */
$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-laptop-sm: 1024px;
$breakpoint-laptop-md: 1200px;
$breakpoint-desktop-md: 1560px;
$breakpoint-desktop-lg: 1940px;

.user_guide_protect {
  text-decoration: none;
}

.user_guide_protect:hover {
  text-decoration: none;
}

.user_guide_protect:active {
  text-decoration: none;
}

html[dir="rtl"] {
  .topNav-container {
    .topNav-items {
      margin: auto auto auto 10px;
    }
  }

  nav {
    .bottom-nav-container {
      background-color: #4ba4b6;
    }

    &.navbar {
      padding: 0 0 0 0.5rem;

      .navbar-container {
        .navbar-logo {
          margin: 0;
        }

        .navbar-nav {
          ul {
            &.navbar-content {
              .dropdown {
                .dropdown-menu {
                  right: 0;
                }

                .orange-arrow {
                  margin: 0 0.3rem;
                  transform: rotate(0deg);
                }

                &.show {
                  .orange-arrow {
                    transform: rotate(-90deg);
                  }
                }
              }
            }
          }
        }
      }

      .social-icons,
      form {
        margin: 0 auto 0 0;
      }
    }
  }
}

/* Top Nav */
.topNav-container {
  width: 100vw;
  padding: 10px;
  font-family: "HelveticaNeueLTArabic-Light";

  @media (max-width: 991px) {
    display: none;
  }

  background: $navSubColor;
  display: flex;
  color: #ffffff;
  padding: 0.25rem 0.5rem;

  ul {
    display: flex;
    gap: 1rem;

    li {
      display: flex;
      align-items: center;
      justify-content: center;

      h6 {
        height: 5px;
        font-size: 88%;
      }
    }

    .license-icon {
      margin: 0 0.25rem;
    }

    &.topNav-items {
      margin: auto 10px auto auto;
      font: normal normal 300 12px/27px HelveticaNeueLTArabic-Light;
    }
  }

  .social-icons {
    display: flex;
    gap: 0.5rem;
    flex-direction: row-reverse;

    figure {
      display: flex;
      margin: 0 2rem;
      gap: 5px;
    }

    .nav-contact {
      display: flex;
      flex-direction: row-reverse;
      gap: 0.25rem;
      margin: 0 0.5rem;
      font: normal normal normal 800 22px/27px Impact;
    }

    @media (max-width: $breakpoint-laptop-md) {
      display: none;
    }
  }
}

.bottom-nav-container {
  background-color: #4ba4b6;
  align-items: center;
  border: 1px solid;

  a {
    color: #fff;
  }
}

nav {
  &.nav-container {
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    z-index: 600;
  }

  &.navbar {
    width: 100vw;
    display: flex;
    background: $navMainColor;
    padding: 0;

    .navbar-container {
      align-items: stretch;
      max-width: 100vw;
      padding: 0;

      .navbar-logo {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0 0.5rem 0 1.5rem;
        color: #fff;
        padding: 0.5rem;
        justify-content: center;

        @media (max-width: 1200px) {
          padding: 0.8rem 0.5rem;
        }

        &:hover {
          background: $navSubColor;
        }
      }

      .dropdown {
        display: flex;
        position: relative;
        height: 100%;
        justify-content: center;

        @media (max-width: 1200px) {
          flex-direction: column;
          // width: 100%;
        }

        .orange-arrow {
          margin: 0 0.3rem;
          transform: rotate(180deg);
        }

        @media (max-width: $breakpoint-desktop-md) {
          padding: 0 0.5rem;

          span {
            display: none;
          }
        }

        @media (max-width: 400px) {
          padding: 0px 4px 7px;
        }
      }

      .navbar-collapse {
        .navbar-nav {
          justify-content: space-between;
          height: 100%;
          flex: 1;

          ul {
            &.navbar-content {
              @media (max-width: 1660px) {
                gap: 0;
              }

              @media (max-width: $breakpoint-laptop-sm) {
                gap: 0rem;
              }

              @media (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
              }

              a {
                @media (min-width: 992px) {
                  font-size: 82%;
                }
              }

              div {
                button {
                  @media (min-width: 992px) {
                    font-size: 82%;
                  }
                }
              }

              display: flex;
              gap: 1rem;
              margin: 0 0.5rem;

              .nav-item {
                color: #fff;

                .nav-link {
                  color: #fff;
                }

                .orange-arrow {
                  margin: 0 0.3rem;
                  transform: rotate(180deg);
                }
              }

              a {
                display: flex;
              }

              .nav-link {
                color: #fff;
                padding: 0.5rem;
                border-radius: 0;
                font-size: 16px;

                &:focus {
                  box-shadow: none;
                }

                &:hover {
                  color: #fff;
                  background: $navSubColor;
                }
              }

              button {
                background: none;
                text-align: start;
                padding: 0;

                &.lang-btn {
                  border: none;
                  color: #fff;

                  &:hover {
                    background: $navSubColor;
                  }
                }
              }
            }
          }

          .bottom-nav-Btns {
            margin-inline-start: auto;
            display: flex;
            margin: 0.5rem 0;
            align-items: center;

            .navBtn-main,
            .navBtn-sub {
              border-radius: 2rem;
              margin: 0 0.25rem;
              justify-content: center;
              transition: 0.4s;
              box-shadow: 0px 3px 6px #00000027;
              height: 40px;
              font-size: 13px;
              padding-inline: 16px;
              width: fit-content;

              &:hover {
                color: inherit;
              }
            }

            .navBtn-sub {
              margin-inline-end: 15px;
              background-color: #2fa3b5;

              // width: 150px;
              &:hover {
                background-color: #fff;
                color: #e1591f;
              }
            }

            .navBtn-main {
              background: #e1591f;

              &:hover {
                background-color: #f37021;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  li,
  a {
    display: flex;
    align-items: center;
  }

  a {
    gap: 0.5rem;
  }

  a.active,
  li.active {
    background: $navActiveColor;
  }

  .social-icons,
  form {
    margin-left: auto;
  }

  .nav-toggleContainer {
    display: flex;

    .nav-toggle {
      display: none;
      background: none;
      border: none;
      padding: 0.25rem 0.75rem;
      border-radius: 4px;
      align-self: center;
    }

    .navbar-content {
      display: none;
      position: absolute;
      padding-top: 1rem;
      width: 100%;
      left: -8px;

      &.show {
        display: flex;
        flex-direction: column;
        background: $navMainColor;
      }

      li {
        width: 100%;
      }
    }
  }

  .social-icons {
    display: none;
    gap: 0.5rem;

    @media (max-width: 400px) {
      gap: 0;
    }

    figure {
      display: flex;
      margin: 0;
      gap: 5px;
    }

    .nav-contact {
      display: flex;
      flex-direction: row-reverse;
      gap: 0.25rem;
      text-align: left;
      font: normal normal normal 800 22px/27px Impact;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      cursor: pointer;

      @media (max-width: $breakpoint-laptop-md) {
        flex-direction: row-reverse;
      }
    }

    .phone-icon {
      @media (max-width: 400px) {
        margin-right: 4px;
      }
    }

    @media (max-width: $breakpoint-laptop-md) {
      display: flex;
    }
  }
}
