.careers-form-banner__section {
  background-color: #1397ab;
  margin-bottom: 4rem;
  .joinus-header {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ($breakpoint-md)) {
      flex-direction: column;
      text-align: center;
    }
    .banner-text-container {
      padding: 1rem 0;
    }
    h2 {
      font-size: 50px;
      padding: 3rem 0 1rem 0;
    }
    p {
      font-size: 30px;
      @media (max-width: ($breakpoint-md)) {
        width: 100%;
      }
    }
  }
}
.careers-form-main__section {
  width: 90%;
  margin: auto;
  margin-bottom: 5rem;
  @media (max-width: ($breakpoint-md)) {
    width: 95%;
  }
  .careers-form-main__header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding: 4rem 0 0.5rem 0;
    .link {
      color: white;
      @media (max-width: $breakpoint-md) {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
      p {
        font-size: 14px;
      }
      .link-icon {
        width: 10px;
        height: 10px;
      }
      .flippedIcon {
        transform: scaleX(-1);
        width: 10px;
        height: 10px;
      }
    }
  }
  .form-main-section {
    background-color: #f6f6f6;
    border-radius: 10px;
    h3 {
      color: #f37021;
    }
    p {
      color: #707070;
    }
    .form-first-column {
      order: 1;
      padding: 3rem 1rem;
      @media (max-width: $breakpoint-md) {
        order: 2;
      }
      .form-container {
        padding: 1rem 0;
        color: #333333;
        .biography-area {
          resize: none;
        }
        .form-file-input {
          display: none;
        }
        .file-input-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 1rem;
          border-color: #b7b7b7;
          border-width: 1px;
          border-style: dashed;
          .file-input-row {
            display: flex;
            align-items: center;
          }
          .edit-icon {
            border: 2px solid #16abc1;
            border-radius: 5px;
            padding: 0.5rem;
            cursor: pointer;
          }
        }
        .file-input-text {
          color: #16abc1;
          margin: 0 0.5rem;
        }
        .file-input {
          border-color: #b7b7b7;
          border-width: 1px;
          border-style: dashed;
          padding: 1rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          .upload-icon {
            width: 30px;
            height: 25px;
          }
        }
        .required-field {
          border: 1px solid red;
        }
        .error-msg {
          color: red;
        }
        .show-error {
          visibility: visible;
        }
        .hide-error {
          visibility: hidden;
        }
        .form-row {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .send-btn {
          width: 100%;
          color: white;
          margin: 0;
          justify-content: center;
        }
        .length-row {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .form-last-column {
      order: 2;
      @media (max-width: $breakpoint-md) {
        order: 1;
      }
      .form-image {
        object-fit: cover;
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
