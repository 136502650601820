button.common-orange-button {
  background: #f37021;
  border: none;
  border-radius: 2rem;
  padding: 9px 2em;
  display: flex;
  box-shadow: 0 2px 5px rgb(0 0 0 / 16%);
  align-items: center;
  gap: 5px;
  margin-top: 25px;
  font-family: HelveticaNeueLTArabic-Bold;

  &--disable {
    opacity: 0.6;
  }
}
