@font-face {
  font-family: "HelveticaNeueLTArabic-Bold";
  src: url("../../assets/fonts/HelveticaNeueLTArabic-Bold.ttf");
}
@font-face {
  font-family: "HelveticaNeueLTArabic-Light";
  src: url("../../assets/fonts/HelveticaNeueLTArabic-Light.ttf");
}

.font {
  &.bold {
    font-family: "HelveticaNeueLTArabic-Bold";
  }

  &.light {
    font-family: "HelveticaNeueLTArabic-Light";
  }
}

.text-muted {
  color: #6c757d !important;
  direction: rtl;
}
.text-aligned-rtl {
  direction: rtl !important;
}
