main {
  position: relative;
  padding-top: 6rem;

  @media (max-width: $breakpoint-md) {
    padding-top: 4.7rem;
  }

  .apply-for-loan-btn {
    &:hover {
      color: #fff;
    }

    background: rgb(243, 112, 33);
    outline: 8px solid rgba(243, 112, 33, 0.4);
    position: fixed;
    bottom: 30px;
    left: 70px;
    padding: 6px 30px;
    border-radius: 25px;
    font-size: 20px;
    z-index: 1000;

    @media (max-width: $breakpoint-sm) {
      inset-inline: 0;
      inset-block-end: 0;
      padding-block: 14px;
      outline: none;
      border-radius: 0px;

      p {
        text-align: center;
      }
    }
  }
}
