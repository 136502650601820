.careers-banner__section {
  .careers-header__header {
    padding: 1rem 5rem;
    background: transparent
      linear-gradient(91deg, #0aa0b5 0%, #005b69 100%, #005b69 100%) 0% 0%
      no-repeat padding-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: ($breakpoint-md)) {
      order: 2;
    }
    h2 {
      padding-block: 1.5rem;
      font-size: 50px;
    }
    p {
      width: 100%;
      margin-bottom: 2rem;
      font-size: 30px;
      @media (max-width: ($breakpoint-md)) {
        width: 100%;
      }
    }
  }
  .banner-image-container {
    height: 400px;
    padding: 0;
    @media (max-width: ($breakpoint-md)) {
      order: 1;
    }
    .banner-image {
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: 100%;
    }
  }
}
.careers-main__section {
  padding: 3rem 0;
  width: 80%;
  margin: auto;
  .careers-main__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0;
    margin: 0;
  }
  .flippedIcon {
    transform: scaleX(-1);
    width: 10px;
  }
  .paginate__arrows {
    width: 10px;
  }
  .active-page {
    background-color: #005b69;
    text-align: center;
    border-radius: 14px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
  }
  .pagination__atag {
    color: white;
    margin: 0 1rem;
  }
  .pagination__litag {
    font-size: 12px;
    margin: 0 0.5rem;
  }
  .paginate-line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .pagination-page__atag {
    color: white;
    margin: 0 0.5rem;
  }
}
