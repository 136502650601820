$sm-width: 768;
$md-width: 992;
$lg-width: 1200;
$xl-width: 1410;
$plus-one: 1;

%li-style {
  list-style: none;
}

%anchor-style {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

%margin-block-items {
  margin-block-start: 0;
  margin-block-end: 0;
}

@mixin sm-screen {
  @media (max-width:#{$sm-width}px) {
    @content;
  }
}

@mixin md-screen {
  @media (min-width: #{$sm-width + $plus-one}px) and (max-width:#{$md-width}px) {
    @content;
  }
}

@mixin lg-screen {
  @media (min-width: #{$md-width + $plus-one}px) and (max-width:#{$lg-width}px) {
    @content;
  }
}

@mixin xl-screen {
  @media (min-width: #{$lg-width + $plus-one}px) and (max-width:#{$xl-width}px) {
    @content;
  }
}

.footer__section {
  text-align: left;
  font-family: "HelveticaNeueLTArabic";
  height: 638px;
  display: flex;
  color: #ffffff;
  position: relative;
  bottom: -15%;

  .footer__firstSide {
    position: relative;
    background-color: #0f7989;
    padding: 2rem;
    width: 25%;

    .firstSide__copyRight {
      display: none;
      writing-mode: vertical-rl;
      position: absolute;
      text-align: center;
      margin: 0;
      top: 8rem;

      @media screen and (max-width: 565.98px) {
        writing-mode: inherit;
        height: fit-content;
        bottom: 65px;
        right: 50% !important;
        transform: translateX(50%);
        top: auto !important;
      }
    }

    .footer__firstSide__img {
      width: 199px;
      margin-bottom: 3rem;

      img {
        width: 100%;
      }
    }

    .footer__firstSide__bottomImg {
      width: 35%;
      position: absolute;
      right: 0;
      bottom: 0;

      @media screen and (max-width: 767.98px) {
        bottom: 58px;
      }

      img {
        width: 100%;
      }
    }

    h3 {
      // width: 100%;
      // font-size: 130%;
      line-height: 1.5;
      margin-bottom: 0.8rem;
    }

    p {
      // width: 95%;
      // font-size: 113%;
      line-height: 1.9rem;
      width: 90%;
    }

    .footer__firstSide__list {
      display: flex;
      justify-content: start;
      margin-top: 2rem;
      margin-bottom: 2rem;
      gap: 10%;

      li {
        @extend %li-style;
        margin-bottom: 1.2rem;

        a {
          @extend %anchor-style;
        }
      }
    }

    .footer__firstSide_list__icons {
      display: none;
      position: absolute;
      inset-block-start: 40%;
      inset-inline-start: 0;
      padding-inline-start: 1rem;

      li {
        @extend %li-style;
        margin-bottom: 0.8rem;

        a {
          @extend %anchor-style;
        }
      }
    }
  }

  .footer__secondSide {
    background-color: #1294a7;
    width: 75%;
    position: relative;
    padding: 11rem 2rem 0rem;

    .footer__socialContacts__icons {
      background-color: #0f7989;
      position: absolute;
      width: fit-content;
      text-align: center;
      padding: 2rem 1.3rem 0 0.9rem;
      right: 0;
      bottom: 0;

      .footer__list__icons {
        padding: 0;

        li {
          @extend %li-style;
          margin-bottom: 0.5rem;

          a {
            @extend %anchor-style;
          }
        }
      }

      .footer__copyright {
        margin: 0.5rem auto;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        line-height: 1.3;

        p {
          @extend %margin-block-items;

          a {
            @extend %anchor-style;
          }
        }
      }
    }

    .footer__secondSide__upperItems {
      display: flex;
      justify-content: space-evenly;

      .footer__secondSide__upperItem {
        // margin-right: 3.5rem;
        h4 {
          margin-bottom: 1.5rem;
          font-size: 1.25rem;
        }

        li {
          @extend %li-style;
          margin-bottom: 1.2rem;

          a {
            @extend %anchor-style;
          }
        }
      }
    }

    .footer__secondSide__bottomItems {
      padding: 6rem 4rem 0 0;
      display: flex;
      justify-content: space-evenly;

      .footer__secondSide__bottomItems__list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 0.7;
        gap: 2%;

        li {
          &:nth-child(2) {
            margin-inline-start: 1.5rem;

            @media screen and (max-width: 991.98px) {
              margin-inline-start: unset;
            }
          }

          @extend %li-style;

          a {
            @extend %anchor-style;

            img {
              width: 180px;
            }
          }
        }
      }
    }
  }
}

html[dir="rtl"] {
  .footer__section {
    text-align: right;

    .footer__firstSide {
      .footer__firstSide__content {
        padding: 10px;
      }

      .footer__firstSide__list {
        justify-content: start;
        margin-left: 0;
        padding: 0;
      }

      h3 {
        line-height: 1.5;
        margin-bottom: 0.8rem;
      }

      p {
        line-height: 1.9rem;
      }
    }

    .footer__secondSide {
      padding-left: 5rem;

      .footer__socialContacts__icons {
        left: 0;
        right: calc(100% - 70px);
        width: 70px;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @include sm-screen {
          right: 90%;
        }

        @include md-screen {
          padding-right: 0.5rem !important;
          height: 61%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          right: 89%;
        }

        @include lg-screen {
          right: 92% !important;
          height: 80%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }

      .footer__secondSide__bottomItems {
        padding: 6rem 0 0 0;
      }
    }
  }
}

@include sm-screen {
  .footer__section {
    .footer__firstSide {
      width: 100%;
      padding-bottom: 0;
      padding-left: 3rem;
      padding-right: 3rem;

      .footer__firstSide__bottomImg {
        width: 23%;
      }

      .firstSide__copyRight {
        display: inline;
        left: 4px;
      }

      .footer__firstSide__content {
        position: absolute;
        inset-inline-start: 20%;
      }

      .footer__firstSide_list__icons {
        display: inline;
      }
    }

    .firstSide__copyRight {
      writing-mode: vertical-rl;
      margin: 0;
      top: 20rem !important;
      left: 5%;
      width: fit-content !important;
    }

    .footer__firstSide_list__icons {
      top: 10% !important;
    }

    .footer__secondSide {
      display: none;
    }
  }
}

@include md-screen {
  .footer__section {
    height: auto;

    .footer__firstSide {
      width: 35%;

      .footer__firstSide__content {
        h3 {
          font-size: 100%;
          width: 90%;
          line-height: 130%;
        }
      }

      p {
        font-size: 100%;
      }
    }

    .footer__secondSide {
      width: 65%;
      padding-top: 4rem;
      padding-bottom: 1.5rem;

      .footer__secondSide__upperItems {
        flex-wrap: wrap;

        .footer__secondSide__upperItem {
          //   margin-right: 0;
          margin-top: 10px;
          flex: 35%;

          &:nth-of-type(3),
          &:nth-of-type(4) {
            margin-block-start: 2.5rem;
          }
        }
      }

      .footer__secondSide__bottomItems {
        padding: 2rem 3.2rem 0 0;

        .footer__secondSide__bottomItems__list {
          flex-wrap: wrap;
          padding-left: 0;

          li {
            margin-bottom: 1rem;
            text-align: start;

            &:nth-child(1) {
              flex: 100%;
            }

            &:nth-child(2) {
              flex: 100%;
            }

            &:nth-child(3) {
              margin-left: 0;
              flex: 45%;
            }

            &:nth-child(4) {
              flex: 45%;
            }
          }
        }
      }

      .footer__socialContacts__icons {
        padding-right: 3rem;
      }
    }
  }
}

@include lg-screen {
  .footer__section {
    .footer__secondSide {
      padding-left: 0;

      .footer__secondSide__upperItems {
        padding-left: 25px;

        .footer__secondSide__upperItem {
          padding: 0;
          //   margin-right: 0.5rem;
          //   margin-right: 10px;
          // width: 288px;

          margin-left: 10px;

          h4 {
            font-size: 100%;
          }

          li {
            a {
              font-size: 90%;
            }
          }
        }
      }

      .footer__socialContacts__icons {
        width: fit-content;
        padding-right: 1rem;
      }

      .footer__secondSide__bottomItems {
        margin-left: 24px;

        .footer__secondSide__bottomItems__list {
          li {
            margin-bottom: 1rem;
            margin-right: 0.5rem;

            a {
              font-size: 100%;

              img {
                width: 140px;
              }
            }
          }
        }
      }
    }
  }
}

@include xl-screen {
  .footer__section {
    .footer__secondSide {
      padding-left: 0;

      .footer__secondSide__upperItems {
        // padding-inline: 34px;

        .footer__secondSide__upperItem {
          padding-inline: 11px;
          //   margin-right: 0.5rem;
        }
      }

      .footer__socialContacts__icons {
        padding-right: 1rem;
        width: fit-content;
      }

      .footer__secondSide__bottomItems {
        .footer__secondSide__bottomItems__list {
          li {
            margin-bottom: 1rem;
            margin-right: 0.5rem;

            a {
              img {
                width: 160px;
              }
            }
          }
        }
      }
    }
  }
}
