.dropdown-select {
  direction: rtl;
  position: relative;
  width: 100%;
  font-family: "Arial", sans-serif;

  .dropdown-header {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    cursor: pointer;
    background: white;
    font-size: 16px;
    transition: 0.3s;
    gap: 5px;

    &.open {
      border-color: #24abc0;
    }

    .icons {
      display: flex;
      align-items: center;

      .clear-icon {
        margin-right: 8px;
        color: #dc3545;
        cursor: pointer;
      }

      .arrow-icon {
        transition: 0.3s;
      }
    }
  }

  .dropdown-list {
    position: absolute;
    width: 100%;
    background: #fcfcfd;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-top: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    padding: 12px;
    .search-input {
      width: 100%;
      padding: 8px;
      // border: none;
      border: 1px solid #ebebeb;
      border-radius: 8px;
      outline: none;
      // margin: 10;
    }
    .search-icon {
      position: absolute;
      right: 20px; // Positions it to the right
      width: 20px;
      height: 35px;
      color: #6c757d;
      cursor: pointer;
    }

    .options {
      max-height: 200px;
      overflow-y: auto;
      direction: ltr;
      text-align: right;
      // padding: 12px;
      background-color: #fcfcfd;
      padding-inline-end: 12px;
      &::-webkit-scrollbar {
        width: 8px; // Width of the scrollbar
      }
      &::-webkit-scrollbar-track {
        margin-top: 12px;
      }
      &::-webkit-scrollbar-thumb {
        // background: #ccc; // Scroll thumb color
        border-radius: 8px;
        padding-block: 8px; // Creates spacing at top and bottom
      }
      .option {
        padding: 12px;
        padding-block: 16px;
        margin-block: 12px;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid #ebebeb;
        background-color: white;

        &:hover {
          background: #f8f9fa;
        }

        &.selected {
          border: 1px solid #24abc0;
        }
      }
    }
  }
}
.arrow-icon {
  position: absolute;
  left: 12px; // Adjust for alignment
  top: 50%;
  transform: translateY(-50%);
  width: 20px; // Width of the container
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    width: 10px; // Arrow width
    height: 10px; // Arrow height
    border-right: 2px solid #6c757d; // Arrow color
    border-bottom: 2px solid #6c757d;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }
}

.dropdown-header.open .arrow-icon::before {
  transform: rotate(-135deg); // Rotates the arrow upwards when open
}
