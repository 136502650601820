@import "../abstract/variables";

.article-container {
  width: 80%;
  margin: 4rem auto;
  padding: 3rem;
  border-radius: 10px;
  background-color: white;
  color: black;

  h2 {
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 1rem;
    color: #353535;
  }

  p.headline {
    @include remove-more-than(3);
  }

  .article-first-column {
    order: 1;

    @media (max-width: $breakpoint-md) {
      order: 2;
    }
  }

  .article-second-column {
    order: 2;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $breakpoint-md) {
      order: 1;
      margin-bottom: 2rem;
    }

    .article-image {
      width: 100%;
      margin-bottom: 1rem;

      @media (max-width: $breakpoint-md) {
        object-fit: contain;
        height: 50vh;
      }
    }

    .share-article-label {
      align-self: flex-start;
    }

    .share-article-icons {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .share-article-icon {
        margin-inline-end: 1rem;
        cursor: pointer;
      }
    }
  }
}

.article-footer-container {
  width: 80%;
  margin: 4rem auto;

  h3 {
    display: block;
    align-self: flex-start;
    margin-bottom: 2rem;
  }

  .article-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .article-footer__div {
      background-color: #0f7989;
      border-radius: 9px;
      padding: 1.5rem 1rem;
      width: 24%;
      height: 200px;
      margin-bottom: 1rem;

      @media (max-width: $breakpoint-md) {
        width: 48%;
      }

      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }

      p {
        margin-block: 2rem;
        height: 35%;
        overflow: hidden;
      }
    }
  }
}
