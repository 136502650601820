.notify-modal {
  .notify-modal__body {
    p {
      text-align: center;
      margin-block: 40px;
      font-size: 20px;
      width: 280px;
      margin-inline: auto;
    }
    .success-img,
    .error-img {
      margin-top: 20px;
      border-radius: 50%;
      display: block;
      margin-inline: auto;
    }
    .success-img {
      margin-bottom: -25px;
    }
  }
}

.close-button-modal {
  position: absolute;
  top: 5%;
  right: 2.5%;
  cursor: pointer !important;
  width: 20px;
  height: 20px;
}
