.row-container {
  display: flex;
  flex-direction: column;

  // row-gap: 1rem;
  padding: 1rem 1rem;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin-block: 0.5rem;
  direction: rtl;
}

.text-aligned-rtl {
  direction: rtl;
}
.text-optional {
  color: #6b7280;
}
.file-uploaded {
  color: #6b7280;
}
.input-row {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  img {
    position: absolute;
    right: 10px;
    top: 7px;
    @media screen and (max-width: 768px) {
      top: 5px;
    }
  }
  .validate-icon {
    position: absolute;
    top: 10px;
    left: 10px !important;
    right: unset !important;
    width: 1rem;
    height: 1rem;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
.input-row input:disabled {
  background: none;
}
.no-border {
  border: none !important;
}
