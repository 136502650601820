$breakpoint-tablet: 768px;
$breakpoint-laptop-sm: 1024px;
$breakpoint-laptop-md: 1200px;
$breakpoint-desktop-md: 1560px;

html[dir="rtl"] {
  .branches-container {
    .branches_btn_container {
      a {
        &:hover {
          img {
            margin-left: 0;
            transform: translateX(-20px);
          }
        }
        img {
          transform: rotate(0deg);
        }
      }
    }
  }
  .branches-box {
    option {
      &.sel-branch-head {
        &:before {
          right: 0px;
          transform: rotate(0deg);
        }
      }
    }
  }
}

.branches-container {
  background: #2fa3b5;
  color: #fff;
  padding: 4rem 3rem;
  display: flex;
  @media (max-width: $breakpoint-md) {
    padding: 2rem 3rem;
    flex-direction: column;
    align-items: flex-start;
    height: 85rem;
  }
  .branches-list {
    flex: 1;
    margin-inline-end: 40px;
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
  .bg-line {
    font-family: "HelveticaNeueLTArabic-Bold";
    z-index: 97;
    position: relative;
    &:before {
      position: absolute;
      top: 110%;
      height: 10px;
      content: "";
      background-color: rgb(243, 112, 33);
      min-width: 60px;
      max-width: 60px;
      width: 100%;
      z-index: -1;
    }
  }

  .branches_btn_container {
    text-align: center;
    padding-top: 20px;
    margin-top: 40px;

    a {
      color: #fff;

      &:hover {
        img {
          transform: rotate(180deg) translateX(-20px);
        }
      }

      img {
        transform: rotate(180deg);
        transition: 1s;
      }
    }
  }

  #map {
    height: 600px;
    width: 35vw;
    flex: 1;
    margin: auto;
    background: #000;
    @media (max-width: 991.98px) {
      width: 100%;
    }
    .content {
      color: #000;
      padding: 1rem 1rem 0 0;

      a {
        color: #337ab7;
        font-size: 18px;

        &:hover {
          color: #23527c;
          text-decoration: underline;
        }
      }
    }
  }
  // }

  .branches-box {
    background-color: #0598ab;
    height: 390px !important;
    width: 90vw;
    padding: 20px;
    border: 0;
    box-shadow: none;
    border-radius: 0px;
    margin-top: 40px;
    overflow: auto;
    @media (max-width: 991.98px) {
      width: 100%;
    }
    option {
      &.sel-branch-item {
        padding: 2px 30px;
        color: #fff;
        position: relative;
        font-family: "HelveticaNeueLTArabic-Light";
        white-space: normal !important;

        &:hover,
        &:active,
        &:focus,
        &:checked,
        &::selection {
          background-color: #f37021 !important;
          color: #fff !important;
        }
      }

      &.sel-branch-head {
        color: #fff;
        font-size: 25px;
        position: relative;
        padding-right: 30px;
        padding-top: 10px;
        font-family: "HelveticaNeueLTArabic-Bold";

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 15px solid rgb(243, 112, 33);
          position: absolute;
          left: 0px;
          transform: rotate(180deg);
          top: calc(40% + 5px);
          transition: 0.4s linear;
        }
      }
      padding: 2px 30px;
      white-space: normal !important;
    }

    select {
      background-color: #0598ab;
      height: 530px !important;
      width: 100%;
      padding: 20px;
      border: 0;
      box-shadow: none;
      border-radius: 0px;
      margin-top: 40px;
      overflow: auto;
    }
  }
}
