$breakpoint-phone: 640px;
$breakpoint-tablet: 768px;
$breakpoint-laptop-sm: 1024px;
$breakpoint-laptop-md: 1200px;
$breakpoint-desktop-md: 1560px;
$breakpoint-desktop-lg: 1940px;

#root > main {
  overflow: hidden; //* footer and amanApp__advertise__section braeks layout
  color: #fff;

  .f-xxl {
    font-size: 50px;

    @media (max-width: $breakpoint-desktop-md) {
      font-size: 40px;
    }

    @media (max-width: $breakpoint-laptop-sm) {
      font-size: 35px;
    }
  }

  .f-xl {
    font-size: 30px;

    @media (max-width: $breakpoint-laptop-sm) {
      font-size: 24px;
    }
  }

  .f-mxl {
    font-size: 37px;

    @media (max-width: $breakpoint-laptop-sm) {
      font-size: 28px;
    }
  }

  .f-l {
    font-size: 22px;
  }

  .f-sm {
    font-size: 16px;

    @media (max-width: $breakpoint-tablet) {
      font-size: 14px;
    }
  }

  .f-md {
    font-size: 20px;

    @media (max-width: ($breakpoint-laptop-md)) {
      font-size: 16px;
    }
  }
}

.svgBranches {
  font-size: 32px;
}

.title-with-slider {
  height: calc(100vh - 6rem);
  background-color: #15abc1;

  @media (max-width: ($breakpoint-laptop-md)) {
    height: auto;
    flex-direction: column;
  }

  .area-title__arrow-visual {
    position: absolute;
    top: 0;
    inset-inline-start: -45px;
    height: 100%;
    z-index: 78;
    transform: rotate3d(1, 1000, 1, 180deg);

    @media (max-width: ($breakpoint-laptop-md)) {
      display: none;
    }
  }

  .area-slider {
    position: relative;

    @media (max-width: ($breakpoint-laptop-md)) {
      width: unset;
    }

    .hero_carousel_btn {
      position: absolute;
      bottom: 75px;
      z-index: 78;
      background: none;
      border: none;
      display: inline-block;
      transform: scaleX(-1);

      @media (max-width: ($breakpoint-laptop-md)) {
        bottom: 75px;
      }

      @media screen and (max-width: 767.98px) {
        display: none;
      }

      &.--prev {
        inset-inline-end: 1vw;

        @media (max-width: ($breakpoint-laptop-md)) {
          inset-inline-end: 1vw;
        }
      }

      &.--next {
        inset-inline-start: 4vw;

        @media (max-width: ($breakpoint-laptop-md)) {
          inset-inline-start: 1vw;
        }
      }
    }

    .area-slider__slider {
      height: 100%;
      z-index: 66;
      position: relative;

      .area-slider__slider__item {
        position: relative;
        width: 100%;
        height: calc(100vh - 6rem);

        @media (max-width: ($breakpoint-laptop-md)) {
          height: auto;
        }

        img {
          height: 100%;
          object-fit: cover;

          @media (max-width: ($breakpoint-laptop-md)) {
            height: 600px;
          }
        }

        .area-slider__slider__item__label {
          padding: 2rem 0;
          position: absolute;
          background-color: rgba(87, 77, 77, 0.82);
          color: white;
          bottom: 0;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          @media (max-width: ($breakpoint-laptop-md)) {
            position: relative;
            background: none;
          }

          .text {
            padding-inline-start: 3rem;
            width: 68%;

            @media screen and (max-width: 655.98px) {
              width: 90%;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .area-title {
    height: calc(100vh - 6rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    position: relative;
    padding: 0;
    padding-bottom: 15rem;

    @media (max-width: ($breakpoint-laptop-md)) {
      width: unset;
      padding-bottom: 2rem;
      height: auto;
    }

    .area-title__text {
      padding-inline-end: 1rem;
      padding-inline-start: 3rem;
      z-index: 88;

      @media (max-width: ($breakpoint-laptop-md)) {
        width: 100%;
        text-align: center;
        padding: 1rem;
      }

      .secandary-text-sec-1 {
        @media (max-height: 889.98px) {
          display: none;
        }
      }

      p {
        @media (max-width: ($breakpoint-laptop-md)) {
          display: none;
        }
      }

      .actions {
        display: flex;
        gap: 15px;
        margin-top: 25px;

        @media (max-width: 1920px) {
          flex-direction: column;
          align-items: center;
        }

        @media (max-width: ($breakpoint-laptop-md)) {
          margin-top: 3rem;
          flex-direction: column;
          align-items: center;
        }

        // .amnmf-btn-outlined {
        //   @media (max-width: ($breakpoint-laptop-md)) {
        //     display: none;
        //   }
        // }
      }

      .area-title__logo {
        width: 300px;
      }
    }
  }

  .area-title::after {
    content: "";
    width: 10px;
    height: 166px;
    background-color: #15abc1;
    position: absolute;
    top: 43%;
    left: -1.2%;
    z-index: 1000;
  }

  .area-title__stats {
    width: 100%;
    padding: 0;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    @media (max-width: ($breakpoint-laptop-md)) {
      display: none;
    }

    .floating-arrow {
      position: absolute;
      top: -9px;
      inset-inline-start: 70px;
      width: 199px;
      height: 105px;
      z-index: 100;
      transform: rotate3d(1, 1000, 1, 180deg);
    }
  }

  .area-title__stats__item {
    background-color: #2fa3b5;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.--dark {
      background-color: #197989;
      height: 250px;

      @media (min-width: 1201px) and (max-width: 1500px) {
        h4 {
          font-size: 25px !important;
        }
      }
    }

    &.--light {
      background-color: #2fa3b5;
      height: 225px;
    }

    &.--orange {
      background-color: #e1591f;
      position: relative;
      z-index: 80;

      &:before {
        position: absolute;
        content: " ";
        height: 100%;
        width: 10px;
        right: -10px;
        background-color: #e1591f;
      }
    }
  }
}

.amanmf__loan-benefits {
  height: fit-content;
  padding-top: 12rem;
  padding-bottom: 8rem;
  position: relative;
  background-color: #197989;
  background: linear-gradient(-130deg, #15abc1 -10%, #197989 150%);

  .amanmf__loan-benefits__title {
    text-align: center;
  }

  .amanmf__benefits-container {
    padding-top: 5rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    direction: ltr;
    gap: 10px;

    @media (max-width: ($breakpoint-laptop-md)) {
      flex-direction: column;
      padding: 5rem 1rem 1rem 1rem;
    }

    .amanmf__benefit-card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-left: 0px;
      background-color: #0f7989;
      padding: 10px;
      width: 300px;
      height: 340px;
      box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 16%);
      border-radius: 9px;
      margin-top: 10px;
      z-index: 5;

      @media (max-width: ($breakpoint-laptop-md)) {
        width: 100%;
        flex-direction: row;
        height: auto;
      }

      .amanmf__img-container {
        width: 164px;
        height: 115px;

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }

      .amanmf__benefit-text {
        text-align: center;

        @media (max-width: ($breakpoint-laptop-md)) {
          width: 100%;
        }
      }
    }
  }

  .path-container {
    position: absolute;
    top: 80px;
    right: 0px;
    height: 476px;
    width: 512px;
    z-index: 0;

    @media (max-width: ($breakpoint-tablet)) {
      right: -375px;
    }

    &.aos-animate {
      #kit-flying {
        animation: alongPath3-data-v-29c4734a 2s linear forwards;
        offset-distance: 0%;
        animation-fill-mode: forwards;
        offset-path: path(
          "M 630 300 Q 560 550 377 466 Q 310 390 260 390 Q 140 290 160 190 Q 180 145 280 150 Q315 140 315 105 Q 345 -50 105 30"
        );
        transform: rotateZ(360deg);
      }

      .c-line[data-v-29c4734a] {
        animation: c-dashed-line-path-data-v-29c4734a 2s linear forwards;
        fill: none;
        stroke: #fff;
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
        stroke-width: 4;
      }

      .c-dashed-line[data-v-29c4734a] {
        fill: none;
        stroke: #16a2b7;
        stroke-dasharray: 4 4;
        stroke-width: 8;
      }
    }
  }
}

.amanmf__aman-branches {
  background-color: #15abc1;
  height: 734px;
  padding: 8rem 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ($breakpoint-tablet)) {
    height: fit-content;
    flex-direction: column-reverse;
  }

  .amanmf__aman-branches__text {
    display: flex;
    flex-direction: column;
    width: 55%;
    height: 100%;
    justify-content: center;

    @media (max-width: ($breakpoint-tablet)) {
      width: 90%;
      text-align: center;
    }

    h2 {
      @media (max-width: ($breakpoint-tablet)) {
        position: absolute;
        top: 50px;
        right: 50%;
        transform: translateX(40%);
      }
    }

    p {
      @media (max-width: ($breakpoint-tablet)) {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        line-height: 25px;
      }
    }

    .search {
      display: flex;
      align-items: center;

      @media (max-width: ($breakpoint-tablet)) {
        justify-content: center;
      }
    }
  }

  .amanmf__aman-branches__map {
    height: 100%;
    width: 30%;
    position: relative;

    @media (max-width: ($breakpoint-tablet)) {
      width: 50%;
      height: initial;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    svg {
      width: 100%;
      height: 100%;
      direction: rtl;

      @media (max-width: ($breakpoint-laptop-md)) {
        margin: auto;
      }
    }
  }
}

.amanmf__start-project {
  background-color: #2fa3b5;
  height: 550px;
  padding: 8rem 20px;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ($breakpoint-laptop-md)) {
    height: fit-content;
  }

  .amanmf__start-project__title {
    position: relative;
    inset-inline-end: 240px;
    top: 230px;
    width: 55%;

    @media (max-width: ($breakpoint-laptop-md)) {
      top: 50px;
      inset-inline-end: 0;
      width: 75%;
    }

    h2 {
      position: relative;
    }

    img {
      position: absolute;
      z-index: 0;
      top: -106px;
      inset-inline-start: 160px;
      transform: rotate3d(1, 1000, 1, 180deg);

      @media (max-width: ($breakpoint-laptop-md)) {
        top: -90px;
        right: unset !important;
        inset-inline-start: auto;
      }
    }
  }

  .amanmf__image-container {
    display: flex;
    align-items: flex-end;
    position: relative;
    inset-inline-end: 20px;
    bottom: -60px;

    @media (max-width: ($breakpoint-tablet)) {
      flex-direction: column-reverse;
      width: 100%;
      inset-inline-end: 0;
    }

    .financial__grow-shape__image {
      @media (max-width: ($breakpoint-laptop-md)) {
        width: 250px;

        img {
          width: 95px;

          &:first-child {
            width: 272px;
          }
        }
      }
    }

    .amount-range {
      position: relative;
      display: flex;
      inset-inline-start: 70px;

      @media (max-width: ($breakpoint-tablet)) {
        width: 100%;
        inset-inline-start: 0;
      }

      @media (max-width: ($breakpoint-laptop-md)) {
        flex-direction: column;
      }

      .box {
        padding: 20px 0;
        color: white;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        width: 25vw;
        text-align: center;

        @media (max-width: ($breakpoint-tablet)) {
          width: 100% !important;
        }

        @media (max-width: ($breakpoint-laptop-md)) {
          width: 50vw;
          font-size: 20px !important;
        }

        &.box__orange {
          background-color: #f37021;
          margin-inline-end: 5px;
        }

        &.box__dark-orange {
          background-color: #c34628;
        }
      }
    }
  }
}

.about__section {
  padding: 8rem 0;
  background-color: #197989;
  background: linear-gradient(-130deg, #15abc1 -10%, #197989 150%);
  display: flex;
  justify-content: space-evenly;

  @media (max-width: ($breakpoint-md)) {
    flex-direction: column;
    padding: 2rem;
  }

  .about__section__img {
    width: 250px;
    height: 200px;
    object-fit: contain;

    @media (max-width: ($breakpoint-md)) {
      width: 150px;
      height: 130px;
    }
  }

  .about__section__content {
    .about__section__content__btn {
      padding: 0.6rem 2.5rem;
    }
  }
}

.amanApp__advertise__section {
  .amanApp__advertise__checkList-items {
    @media (max-width: (1650px)) {
      padding-inline-start: 3rem;
    }

    .amanApp__advertise__checkList-item {
      img {
        width: 25px;
      }

      span {
        @media (max-width: (1650px)) {
          width: 75%;
        }
      }

      &:nth-last-child(1) {
        span {
          @media (max-width: (1650px)) {
            width: 75%;
            position: relative;
            top: 15px;
          }
        }
      }
    }
  }

  .amanApp__advertise__appStore {
    display: flex;
    gap: 15px;

    @media (max-width: ($breakpoint-tablet)) {
      text-align: center;
    }

    @media (max-width: 519.98px) {
      flex-direction: column;
    }
  }

  .amanApp__advertise__imgs {
    position: relative;

    .amanApp__advertise__topImg {
      position: absolute;
      left: 38%;
      bottom: 0;
    }
  }
}

.amanmf__success-stories {
  height: 719px;
  background-color: #197989;
  background: linear-gradient(-130deg, #15abc1 -10%, #197989 150%);
  display: flex;
  padding: 100px;
  padding-right: 0px;
  align-items: center;
  position: relative;

  @media (max-width: ($breakpoint-md)) {
    flex-direction: column-reverse;
    padding: 20px !important;
  }

  .amanmf__success-stories__text {
    width: 40%;

    @media (max-width: ($breakpoint-md)) {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 25px;
      margin-bottom: 25px;
      text-align: center !important;
    }

    h3 {
      @media (max-width: ($breakpoint-md)) {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }
    }

    p {
      line-height: 27px;
      padding-inline-end: 8rem;

      @media (max-width: ($breakpoint-md)) {
        display: none;
      }
    }
  }

  .amanmf__success-stories__carousel {
    width: 60%;
    color: white;
    display: flex;
    align-items: center;
    top: 0;

    @media (max-width: ($breakpoint-md)) {
      width: 100%;
    }

    .owl-stage {
      padding-inline-start: 0 !important;
    }

    .owl-item {
      .story-box {
        width: 50%;
        height: 441px;
        background-color: #0f7989;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
        position: relative;
        transition: all 1s;

        &.animate {
          width: 100%;
          display: flex;
          align-items: center;

          @media (max-width: ($breakpoint-xs)) {
            flex-direction: column;
            width: 100%;
            justify-content: center;
          }

          .title {
            display: none;
          }

          .floating-arrow {
            inset-inline-start: 225px;
            opacity: 1;

            @media (max-width: ($breakpoint-laptop-sm)) {
              inset-inline-start: 206px;
            }

            @media (max-width: ($breakpoint-xs)) {
              display: none;
            }
          }

          .sc-modal-toggle {
            width: 100%;
            height: 100%;
          }

          .customer-img {
            clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
            animation: animateImg-data-v-19366a4c 1s linear;
            animation-fill-mode: forwards;
            height: 100%;
            width: 304px;
            object-fit: cover;

            @media (max-width: ($breakpoint-laptop-sm)) {
              width: 285px;
            }

            @media (max-width: ($breakpoint-xs)) {
              width: 100%;
              height: 50%;
              clip-path: none !important;
              animation: none;
            }
          }

          .story-details {
            opacity: 1;
            display: block;

            p {
              @media (max-width: ($breakpoint-xs)) {
                display: none;
              }
            }

            button {
              margin-top: 3rem;
            }
          }
        }

        .title {
          text-align: start;
          padding: 20px;
        }

        .floating-arrow {
          transition: all 1s;
          opacity: 0;
          position: absolute;
          width: 108px;
          height: 96%;
          left: 170px;
          z-index: 1;
          transform: rotate3d(1, 1000, 1, 180deg);
        }

        .sc-modal-toggle {
          background: none;
          border: none;
        }

        .customer-img {
          transition: all 1s;
          width: 380px;
          height: 344px;
          object-fit: cover;
          margin-inline-end: 45px;

          @media (max-width: ($breakpoint-xs)) {
            margin-inline-end: 0;
            object-fit: fill;
          }
        }

        .story-details {
          transition: opacity 1s linear;
          transition-delay: 0.7s;
          opacity: 0;
          display: none;
          padding: 30px 10px;
        }
      }
    }

    .owl-nav {
      position: absolute;
      top: -80px;
      inset-inline-end: 0px;

      @media (max-width: ($breakpoint-tablet)) {
        top: -50px;
      }

      button {
        transform: rotate3d(1, 1000, 1, 180deg);

        @media (max-width: ($breakpoint-tablet)) {
          width: fit-content;
        }

        img {
          @media (max-width: ($breakpoint-tablet)) {
            width: 50px;
          }
        }
      }
    }
  }
}

.amanmf__mobile-app {
  height: 792px;
  background-color: #15abc1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: ($breakpoint-laptop-md)) {
    flex-direction: column;
    height: auto;
    padding: 6rem 0px;
  }

  .amanmf__mobile-app__content {
    position: relative;
    inset-inline-end: 450px;
    z-index: 1;

    @media (max-width: ($breakpoint-laptop-md)) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      top: 0 !important;
      inset-inline-end: 0 !important;
    }

    @media (min-width: 1366px) {
      .amanmf__mobile-app__right[data-v-3f82fe69] {
        margin-left: 720px;
        inset-inline-start: 400px;
      }
    }

    @media (max-width: (1650px)) {
      inset-inline-end: 350px;
    }

    @media (max-width: (1440px)) {
      top: 50px;
      inset-inline-end: 200px;
    }

    .amanApp__advertise__bottomImg {
      height: 500px;
    }

    h2,
    p {
      @media (max-width: ($breakpoint-laptop-md)) {
        text-align: center;
      }
    }

    p {
      @media (max-width: ($breakpoint-laptop-md)) {
        position: none;
        top: 450px;
        background: #1f94a7;
        width: 100%;
        padding: 40px;
        margin-top: 20px;
      }
    }
  }

  .benefit-element[data-v-3f82fe69] {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .benefit-element img[data-v-3f82fe69] {
      width: 25px;
      height: 25px;
    }

    .element-text[data-v-3f82fe69] {
      margin-bottom: 5px;
      color: white;
      margin-right: 10px;
    }
  }

  .amanmf__mobile-app__image {
    position: relative;
    top: 390px;
    inset-inline-start: 1200px;

    .iphone-small--lg-screen {
      position: absolute;
      height: 643px;
      left: 215px;
      bottom: -40px;
      inset-inline-start: -1620px;

      @media (max-width: (1440px)) {
        height: 500px;
        inset-inline-start: -1520px;
      }

      @media (max-width: ($breakpoint-laptop-md)) {
        inset-inline-start: -1350px !important;
      }
    }

    .iphone-large[data-v-3f82fe69] {
      position: absolute;
      bottom: -40px;
      inset-inline-start: 880px;
      height: 751px;
      direction: rtl;

      @media (max-width: (1440px)) {
        height: 600px;
      }
    }
  }

  .amanmf__mobile-app__image {
    #kit-flying2[data-v-3f82fe69] {
      offset-path: path("M -400 50");
      transform: rotateZ(35deg);
    }

    &.aos-animate {
      .c-dashed-line[data-v-3f82fe69] {
        fill: none;
        stroke: #15abc1;
        stroke-dasharray: 4 4;
        stroke-width: 8;
      }

      #kit-flying2[data-v-3f82fe69] {
        animation: alongPath2-data-v-3f82fe69 1s ease-in-out;
        offset-distance: 0%;
        transform: rotateZ(35deg);
        animation-fill-mode: forwards;
        offset-path: path("M -400 50 Q -160 100 -25 30");
      }

      .c-line[data-v-3f82fe69] {
        animation: c-dashed-line-path-data-v-3f82fe69 1.8s ease-in-out forwards;
        fill: none;
        stroke: #fff;
        stroke-dasharray: 940;
        stroke-dashoffset: 940;
        stroke-width: 4;
      }
    }
  }
}

.success-stories__modal {
  color: #fff;

  .modal-dialog {
    width: fit-content;
    min-width: fit-content;

    .modal-content {
      width: fit-content;
      background: #fff;
      border: none;

      .modal-header {
        border: none;

        padding-block-end: 0;

        h3 {
          color: #2e2e2e;
        }

        .orange-arrow {
          width: 25px;
          margin-inline-end: 1rem;
        }

        .btn-close {
          background-image: url(../../assets/images/close.svg);
          font-size: 3rem;
          opacity: 1;
        }
      }

      .modal-body {
        display: flex;
        justify-content: center;
        // width: 95vw;
        position: relative;

        .name-wrapper {
          background-color: #f37021;
          position: absolute;
          inset-inline-start: 0;
          inset-block-start: 50%;
          padding: 8px;
        }

        iframe {
          width: 85vw;
          height: 75vh;
          border-radius: 10px;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .title-with-slider {
    .area-title__stats__item {
      &.--orange {
        &:before {
          content: none;
        }

        &:after {
          position: absolute;
          content: " ";
          height: 100%;
          width: 10px;
          left: -10px;
          background-color: #e1591f;
        }
      }
    }

    .area-title__arrow-visual {
      transform: rotate3d(1, 1000, 1, 0deg);
    }

    .area-title__stats {
      .floating-arrow {
        transform: rotate3d(1, 1000, 1, 0deg);
      }
    }
  }

  .amanmf__start-project {
    .amanmf__start-project__title {
      img {
        transform: rotate3d(1, 1000, 1, 0deg);
      }
    }
  }

  .amanmf__success-stories {
    padding: 100px;
    padding-left: 0;

    .amanmf__success-stories__carousel {
      .owl-nav {
        button {
          transform: rotate3d(1, 1000, 1, 0deg);
        }
      }

      .owl-item {
        .story-box {
          &.animate {
            .customer-img {
              clip-path: polygon(21% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
            }
          }

          .floating-arrow {
            transform: rotate3d(1, 10000, 1, 0);
          }
        }
      }
    }
  }

  .amanApp__advertise__section {
    .amanApp__advertise__imgs {
      right: 25%;

      .amanApp__advertise__topImg {
        left: 88%;
      }

      .amanApp__advertise__bottomImg {
        right: 50%;
      }
    }
  }

  .amanmf__mobile-app {
    .amanmf__mobile-app__image {
      inset-inline-start: 1250px;

      .iphone-large {
        inset-inline-start: -1582px;
      }

      .iphone-small--lg-screen {
        inset-inline-start: -1190px;
      }
    }

    .transparent-btn {
      background-color: transparent;
    }
  }
}

.amanmf__aman-branches__map svg rect[data-v-3758f895] {
  animation: animaterect-data-v-3758f895 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: 0.5s;
}

.amanmf__aman-branches__map svg #Rectangle_1924-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1930-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1925-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1924-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1932-2[data-v-3758f895] {
  animation-delay: 0.1s;
}

.amanmf__aman-branches__map svg #Rectangle_1920-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1929-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1933-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1934-2[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle_1921-2[data-v-3758f895] {
  animation-delay: 0.2s;
}

.amanmf__aman-branches__map svg #Rectangle1[data-v-3758f895],
.amanmf__aman-branches__map svg #Rectangle2[data-v-3758f895] {
  animation: none;
}

@keyframes animaterect-data-v-3758f895 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes alongPath3-data-v-29c4734a {
  100% {
    offset-distance: 100%;
    transform: rotateZ(310deg);
  }
}

.amanmf [data-aos="animate-svg"].aos-animate svg .c-line[data-v-29c4734a] {
  animation: c-dashed-line-path-data-v-29c4734a 2s linear forwards;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 1200;
  /* this is the entire length of the line */
  stroke-dashoffset: 1200;
  /* this is the entire length of the line */
  stroke-width: 4;
}

.amanmf
  [data-aos="animate-svg"].aos-animate
  svg
  .c-dashed-line[data-v-29c4734a] {
  fill: none;
  stroke: #16a2b7;
  /* this must match the background color */
  stroke-dasharray: 4 4;
  /* draws a 10px dash line with a 16px gap between */
  stroke-width: 8;
  /* make the dashed line slightly bigger than the one it's covering */
}

@keyframes c-dashed-line-path-data-v-29c4734a {
  from {
    stroke-dashoffset: 1200;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes alongPath2-data-v-3f82fe69 {
  100% {
    offset-distance: 100%;
    transform: rotateZ(7deg);
  }
}

.amanmf__mobile-app
  [data-aos="animate-svg"].aos-animate
  .c-line[data-v-3f82fe69] {
  animation: c-dashed-line-path-data-v-3f82fe69 1.8s ease-in-out forwards;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 940;
  /* this is the entire length of the line */
  stroke-dashoffset: 940;
  /* this is the entire length of the line */
  stroke-width: 4;
}

.amanmf__mobile-app
  [data-aos="animate-svg"].aos-animate
  .c-dashed-line[data-v-3f82fe69] {
  fill: none;
  stroke: #15abc1;
  /* this must match the background color */
  stroke-dasharray: 4 4;
  /* draws a 4px dash line with a 4px gap between */
  stroke-width: 8;
  /* make the dashed line slightly bigger than the one it's covering */
}

@keyframes c-dashed-line-path-data-v-3f82fe69 {
  from {
    stroke-dashoffset: 940;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 1440px) {
  .iphone-large[data-v-3f82fe69] {
    height: 600px;
  }
}
