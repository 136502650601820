@keyframes headerAnimated {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes coinMove {
  0% {
    transform: translateX(0px) rotate(0deg);
  }

  50% {
    transform: translate(270px) rotate(360deg);
  }

  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
%financial-shape-animation {
  animation-name: headerAnimated;
  animation-duration: 3s;
  animation-iteration-count: initial;
}
.financial__grow-shape__image {
  transform: rotate3d(1, 1000, 1, 180deg);

  img:first-child {
    width: 500px;
  }
  img:last-child {
    width: 150px;
    position: absolute;
    bottom: 0;
    left: 0;
    animation-name: coinMove;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
}

html[dir="rtl"] {
  .financial__grow-shape__image {
    transform: rotate3d(1, 1000, 1, 0deg);
  }
}
