.general-btn {
  border-radius: 2rem;
  margin: 0 0.25rem;
  justify-content: center;
  transition: 0.4s;
  box-shadow: 0px 3px 6px #00000027;
  height: 40px;
}

.amnmf-btn-outlined,
.amnmf-btn-filled {
  display: inline-flex;
  align-items: center;
  background: none;
  height: 42px;
  margin: 0 0.5rem;
  padding: 0.8rem 2.5rem;
  border-radius: 2rem;
  box-shadow: 0px 3px 6px #00000027;
  transition: 0.4s;
  font-family: "HelveticaNeueLTArabic-Light";
}

.amnmf-btn-outlined {
  border: 1px solid #fff;

  &:hover {
    background-color: white;
    color: #e1591f;
  }
}

.amnmf-btn-outlined-orange {
  border: 1px solid #f37021;
  color: #f37021;

  &:hover {
    background-color: #f37021;
    color: #fff;
  }
}

.amnmf-btn-filled {
  background: #e1591f;

  &:hover {
    color: #fff;
    background-color: #f37021;
  }
}

.amnmf-btn-filled-white {
  background: #ffffff;
  color: #197989;

  &:hover {
    background-color: #f37021;
    color: #fff;
  }
}
