.loan-request-section {
  // margin-inline: 10px;
  background-image: url(../../assets/images/landing-page-loan-request.png);
  background-repeat: no-repeat;
  background-size: cover;
  &-sub-modals-title {
    color: #01828d !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    padding-top: 1rem;
    padding-right: 2rem;
  }
  .submit-section {
    background-image: url(../../assets/images/blue-background.png);
    width: 100%;
    height: 30vh;
    display: none;
    // border-top-left-radius: 25px;
    // border-top-right-radius: 25px;
    button {
      display: flex;
      // margin: 1rem auto 0px auto;
      // box-shadow: 0px 3px 6px #3c3c3c42;
      border-radius: 18px;
      width: 50%;
      color: #fff;
      background-color: #e1591f;
      font-size: 16px;
      padding: 20px;
      margin-top: 30px;
      font-family: "HelveticaNeueLTArabic-Bold";
      align-items: center;
      justify-content: center;
      max-width: 308px;
      max-height: 77px;
      min-height: 77px;
    }
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 4rem;
    }
  }

  h1 {
    &.carousel-header {
      font-size: 45px;
      font-weight: 700;
      width: 100%;
      min-width: 630px;
      max-width: 630px;
      height: 146px;
      @media screen and (max-width: 1200px) {
        min-width: unset;
      }
      text-align: flex-start;
      color: #01828d;
    }
  }

  .owl-carousel {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .item {
            img {
              filter: grayscale(100%) brightness(100%);
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .loan-request-form-container-inner {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 70vh;
    z-index: 78;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 700;
      color: #e9581e;
      display: block;
    }
    .title-container {
      width: 36.8%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-inline-start: 2rem;

      @media screen and (max-width: 1280px) and (min-width: 768px) {
        gap: 2rem;
      }
      @media screen and (max-width: 768px) {
        text-align: center;
        padding-top: 3rem;
        padding-inline: 1rem;
      }
    }
    .img-container {
      width: 57%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      img {
        position: absolute;
        // left: 10%;
        bottom: -21%;
        width: 100%;
        height: 120%;
        max-width: 930px;
        max-height: 930px;
        @media screen and (min-width: 1728px) {
        }
        // @media screen and (max-width: 620px) {
        //   // padding-inline: 6rem !important;
        //   width: 100%;
        //   // bottom: -25%;
        //   padding-top: 4rem;
        // }
        // @media screen and (max-width: 620px) {
        //   // padding-inline: 6rem !important;
        //   width: 100%;
        //   // bottom: -25%;
        //   padding-top: 5rem;
        // }
      }
    }
    .btn-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      @media screen and (max-width: 1280px) and (min-width: 768px) {
        // flex-direction: column;
      }
      gap: 20px;
      width: 100%;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    button {
      display: flex;
      // margin: 1rem auto 0px auto;
      box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25),
        inset -2px -4px 4px rgba(0, 0, 0, 0.25);
      transition: all 0.3s ease;
      border-radius: 18px;
      // width: 50%;
      color: #fdfdfd;
      background-color: #e1591f;
      font-size: 16px;
      padding: 20px;
      margin-top: 30px;
      font-family: "HelveticaNeueLTArabic-Bold";
      align-items: center;
      justify-content: center;
      max-width: 300px;
      max-height: 75px;
      min-width: max-content;
      flex: 2;
      &:hover {
        box-shadow: unset;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      .title-container {
        padding-top: 1rem;
        width: 100%;
        height: auto;
      }
      .img-container {
        width: 100%;
      }
    }
  }
  .loan-request-form-container {
    padding: 40px;
    margin: 0 auto 4rem auto;
    max-width: 60%;
    background-color: #fff;
    position: relative;
    top: -80px;
    box-shadow: 1px 4px 50px -1px rgb(0 0 0 / 22%);
    z-index: 77;
    flex: 1;
    .success-form {
      width: 100%;

      .succes-form-icon-text {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f2f2f2;
        padding: 2rem;

        img {
          margin-bottom: 20px;
        }

        h3 {
          font-family: "HelveticaNeueLTArabic-Bold";
          color: black;
          font-size: 20px;
          line-height: 30px;

          &:last-of-type {
            margin-top: 10px;
          }
        }
      }

      .send-form-button {
        display: flex;

        margin: 1rem auto 0px auto;
        box-shadow: 0px 3px 6px #3c3c3c42;
        border-radius: 32px;
        width: 100%;
        color: #fff;
        background-color: #e1591f;
        font-size: 16px;
        padding: 12px;
        margin-top: 30px;
        font-family: "HelveticaNeueLTArabic-Bold";
        align-items: center;
        justify-content: center;
        max-width: 300px;
        max-height: 75px;

        @media screen and (max-width: 767.98px) {
          width: 100%;
        }

        + h3 {
          text-align: center;
          font-family: "HelveticaNeueLTArabic-Bold";
          color: black;
          font-size: 16px;
          line-height: 30px;
          margin-top: 10px;
        }
      }
    }

    @media (max-width: 991.98px) {
      max-width: 90%;
    }

    .loan-request-form {
      .error-state {
        margin-inline-start: 10px;
        display: flex;
        align-items: center;

        p {
          color: #f27123;
        }

        img {
          margin-top: 7px;
          margin-inline-end: 5px;
        }
      }

      .submit-btn {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-element {
          display: flex;
          margin-inline-start: 5px;
        }
      }

      label {
        color: #212529;
        display: block;
        font-size: 17px;
      }

      select {
        height: 45px;
        font-family: "HelveticaNeueLTArabic-Light";
        color: #333333cc;
        line-height: 1.7;
      }
    }
  }
}

html[dir="rtl"] {
  .loan_request_modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .policy-sec {
            p {
              padding: 10px 10px 0 0;
            }

            .loan_request_btn {
              a {
                &:hover {
                  img {
                    transform: translate(-20px);
                  }
                }

                img {
                  transform: rotate(0deg);
                }
              }
            }

            img {
              float: right;
              margin: 0 0 0 10px;

              &.modal-map-icon {
                margin: 20px 0 0 10px;
              }
            }
          }
        }
      }
    }
  }
}

.loan_request_modal {
  .modal-dialog {
    max-width: 950px;
    margin: 1.75rem auto;

    .modal-content {
      height: auto;
      border-radius: 0.3rem;

      @media (max-width: 991.98px) {
        width: 90%;
        margin-inline: auto;
      }

      .modal-body {
        overflow: hidden;

        .policy-sec {
          background-color: #01abc1;
          height: 100%;
          padding: 40px;
          color: #fff;

          @media (max-width: $breakpoint-xs) {
            padding: 40px 15px;
          }

          h2 {
            text-align: center;
            font-size: 20px;
            padding-bottom: 20px;
          }

          h3 {
            line-height: 35px;
            font-size: 24px;
          }

          ol {
            font-size: 17px;
            clear: both;

            li {
              padding: 3px 0;
            }
          }

          img {
            float: left;
            margin-right: 10px;

            &.modal-map-icon {
              margin: 20px 10px 0 0;
            }
          }

          p {
            font-size: 17px;
            padding: 10px 0 0 10px;
          }

          & > button {
            display: block;
            margin: 1rem auto 0px auto;
            width: fit-content;
            color: #fff;
            background-color: #f27022;
            border-color: #f27123;
            font-size: 22px;
          }

          .loan_request_btn {
            margin-top: 40px;

            a {
              font-size: 22px;
              display: flex;

              &:hover {
                color: #fff;

                img {
                  transform: rotate(180deg) translate(-20px);
                }
              }

              img {
                transition: 1s;
                transform: rotate(180deg);
              }
            }
          }

          .bg-line {
            z-index: 97;
            position: relative;

            &:before {
              bottom: 1px;
              height: 10px;
              content: "";
              background-color: #f37021;
              width: 100%;
              bottom: 0px;
              right: 0;
              position: absolute;
              z-index: -1;
            }
          }
        }
      }
    }
  }
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .my-col {
    width: 49%;
  }

  .text-start {
    text-align: start;
  }
}
.no-scroll {
  overflow-y: hidden !important;
}
