.success-story__section {
  padding: 1rem 0 0;
  width: 90%;
  margin: auto;
  .success-story__header {
    h2 {
      padding-block: 1.5rem;
    }
    p {
      width: 700px;
      @media (max-width: ($breakpoint-sm - 1)) {
        width: 100%;
      }
    }
  }
  .success-stories__content__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .success-stories__content__item {
      width: 31%;
      border: 0.8rem #0f7989 solid;
      border-radius: 0.825rem;
      border-bottom: 0rem;
      background-color: #0f7989;
      @media (max-width: ($breakpoint-sm - 1)) {
        width: 100%;
      }
      @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        width: 48%;
      }
      button {
        position: relative;
        width: 100%;
        border: 0;
        background: none;
        padding: 0;
        overflow: hidden;
        .success__story__img {
          border-radius: 0.3125rem;
          transition: transform 0.3s;
          height: 250px;
          object-fit: cover;
          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }
        }
        .video__play__icon {
          width: 20%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .success__story__content__title {
        p {
          background-color: #0f7989;
          padding: 1rem 0;
          margin: 0;
        }
      }
    }
  }
}
html[dir="rtl"] {
  .success-stories__content__items {
    .success-stories__content__item {
      p {
        text-align: right;
      }
    }
  }
}
