.news-row {
  box-shadow: 0px 3px 6px #00000029;
  background-color: #ffffff;
  border-radius: 8px;
  height: 340px;

  @media (max-width: $breakpoint-md) {
    height: 780px;
  }

  h2 {
    color: #343434;
  }

  p {
    color: #707070;
    font-weight: 300;
    margin-bottom: 0.5rem;
    @include remove-more-than(3);

    @media (max-width: $breakpoint-md) {
      @include remove-more-than(3);
    }
  }

  .news-row-image {
    object-fit: cover;
    border-radius: 8px;
    border-end-end-radius: 0;
    border-start-end-radius: 0;
  }

  .news-col {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-width: $breakpoint-md) {
      height: 50%;
    }

    .news-banking {
      align-self: flex-start;
      text-align: center;
      background-color: #00b7c6;
      padding: 0.2rem 0.5rem;
      border-radius: 5px;
    }

    .read-more-btn {
      margin: 0;
      margin-block: 1rem;
      align-self: flex-start;

      @media (max-width: $breakpoint-md) {
        margin-block: 0.5rem;
      }
    }
  }
}
