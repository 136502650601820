$sm-width: 768;
$md-width: 992;
$lg-width: 1200;
$xl-width: 1410;
$plus-one: 1;

@mixin sm-screen {
  @media (max-width:#{$sm-width}px) {
    @content;
  }
}

@mixin md-screen {
  @media (min-width: #{$sm-width + $plus-one}px) and (max-width:#{$md-width}px) {
    @content;
  }
}

@mixin lg-screen {
  @media (min-width: #{$md-width + $plus-one}px) and (max-width:#{$lg-width}px) {
    @content;
  }
}

@mixin xl-screen {
  @media (min-width: #{$lg-width + $plus-one}px) and (max-width:#{$xl-width}px) {
    @content;
  }
}

.online__merchant__images {
  width: 100%;

  @media screen and (min-width: 992px) and (max-width: 1199.98px) {
    flex-direction: column !important;

    > div {
      margin-block-end: 16px;
    }
  }
}

.loan__merchant__section {
  align-items: center;
  justify-content: center;
  padding-inline: 4rem;
  margin-top: 5rem;
  color: #212529;

  .online__merchant__content {
    .online__merchant__img {
      margin-bottom: 3rem;
      width: 380px;
    }

    .online__merchant__header {
      p {
        font-size: 1.3rem;
      }
    }
  }

  .online__merchant__content__conditions {
    border-radius: 0.5rem;
    position: relative;

    .art__arrow__img {
      position: absolute;
      inset-inline-end: 0;
      margin-inline-end: -65px;
      top: 165px;
      width: 250px;
    }

    .online__merchant__content__conditions__card {
      .online__merchant__content__header {
        padding: 4rem 5rem 3rem;

        @media screen and (min-width: 992px) and (max-width: 1199.98px) {
          padding: 4rem 2rem 3rem;
        }

        h5 {
          color: #e1591f;
          font-size: 1.375rem;
        }
      }

      .online__merchant__conditions {
        background-color: #f2f2f2;
        padding: 3rem 5rem 0;
        border-radius: 0.5rem;

        @media screen and (min-width: 992px) and (max-width: 1199.98px) {
          padding: 3rem 2rem 0;
        }

        .online__merchant__conditions__header {
          h6 {
            color: #e1591f;
          }
        }

        .online__merchant__conditions__list {
          li {
            img {
              margin-right: 0.625rem;
            }
          }
        }

        button {
          background-color: #e1591f;
          cursor: pointer;
          box-shadow: none;

          img {
            transform: rotate(180deg);
            transition: transform 0.5s;
          }

          &:hover {
            background-color: #d03e00;

            img {
              transform: rotate(180deg) translateX(-10px);
            }
          }
        }
      }
    }
  }

  .request__form__container {
    border-radius: 0.5rem;
    position: relative;

    .request__form__img {
      position: absolute;
      z-index: 0;
      right: 5%;
      top: -6%;
    }

    .request__form {
      .request__form__label {
        padding-right: 0;
      }

      .request__form__input {
        width: 73%;
        z-index: 0;

        input {
          padding: 0.5rem 1rem 0.5rem;

          &:focus {
            border-color: #ced4da;
            box-shadow: none;
          }
        }
      }

      .request__form__header {
        padding: 1rem 4rem;

        h3 {
          color: #e1591f;
          padding-bottom: 2.2rem;
          font-size: 1.375rem;
        }

        .phone__validation {
          width: 100%;
          margin-inline: 10rem;
        }
      }

      .request__form__bottom__side {
        padding: 2rem 4rem;
        background-color: #f2f2f2;
        border-radius: 0.5rem;
      }

      .btn__request {
        background-color: #e1591f;
        color: #fff;
        margin-top: 10px;
        cursor: pointer;

        &:hover {
          background-color: #d03e00;
        }

        &:disabled {
          background-color: gray;
          cursor: not-allowed;
        }
      }
    }
  }
}

html[dir="rtl"] {
  .loan__merchant__section {
    .online__merchant__img {
      transform: translateX(60px);
    }

    .online__merchant__content__conditions {
      .online__merchant__content__conditions__card {
        .online__merchant__conditions__list {
          li {
            img {
              margin-left: 0.625rem;
              margin-right: 0;
            }
          }
        }

        .online__merchant__conditions {
          button {
            img {
              transform: rotate(0deg);
            }

            &:hover {
              img {
                transform: rotate(0deg) translateX(-10px);
              }
            }
          }
        }
      }
    }

    .request__form__container {
      .request__form__img {
        right: 50%;
        top: -5%;
      }

      .request__form {
        .request__form__label {
          padding-right: calc(var(--bs-gutter-x) * 0.5);
        }
      }
    }
  }
}

@include sm-screen {
  .loan__merchant__section {
    padding-inline: 1rem;
    margin-top: 0rem;

    .online__merchant__content__conditions {
      .art__arrow__img {
        display: none;
      }

      .online__merchant__content__conditions__card {
        .online__merchant__content__header {
          padding: 4rem 2rem 3rem;

          h4 {
            font-size: 17px;
          }

          h5 {
            font-size: 17px;
          }
        }

        .online__merchant__conditions {
          padding: 3rem 1rem 0;
        }
      }
    }

    .online__merchant__content {
      .online__merchant__img {
        transform: translateX(calc(48px / 2)) !important;
        width: 230px;
      }
    }

    .online__merchant__header {
      h1 {
        font-size: x-large;
        padding-inline: 10px;
      }

      p {
        margin-top: 30px;
        font-size: large !important;
        padding-inline: 10px;
      }
    }

    .online__merchant__images {
      width: 100%;

      @media screen and (min-width: 992px) and (max-width: 1199.98px) {
        flex-direction: column !important;
      }

      div {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 170px;
        // background-color: red;
        width: 48%;

        img {
          width: 50% !important;
          object-fit: cover;
        }

        p {
          font-size: 15px;
          width: 100%;
        }
      }
    }
  }

  .request__form__container {
    .request__form__img {
      top: 7% !important;
      display: none;
    }

    .request__form {
      .request__form__header {
        padding: 1rem 1rem !important;

        h3 {
          font-size: 20px !important;
        }

        div {
          display: flex;
          flex-direction: column;

          input {
            width: 100%;
            height: 100%;
            padding: 10px !important;
          }
        }
      }

      .request__form__input {
        width: 100% !important;
        height: 42px;
      }

      .request__form__bottom__side {
        padding: 2rem 1rem !important;

        // background-color: #d03e00 !important;
        .yes_or_no_question {
          label {
            font-size: 14px;
          }

          .yes_or_no_container {
            margin-right: 10px;
          }
        }

        .location-input {
          gap: 10px;
        }
      }
    }
  }
}
